@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*,
::after,
::before {
    box-sizing: border-box
}

* {
    margin: 0;
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    font-family: 'poppins', sans-serif !important;
    transition: background 0.3s ease-in-out;
    -webkit-transition: background 0.3s ease-in-out;
}

#root, body, html {
    min-height: 100%;
    min-height: 100dvh;
    margin: 0;
    font-family: 'poppins', sans-serif !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: #000;
    text-align: left;
    background-color: #0a0d15;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scroll-behavior: smooth;
}

canvas,
img,
picture,
svg,
video {
    display: block;
    max-width: 100%
}

button,
input,
select,
textarea {
    font: inherit
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    overflow-wrap: break-word;
    color: var(--text-color);
}

a{
    text-decoration: none;
    color: black;
    cursor: pointer;
}

::selection {
    background: #00000010;
    color: currentColor
}

::-moz-selection {
    background: #00000010;
    color: currentColor
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent
}

/* MAPBOX TITLE HIDE */
.mapboxgl-ctrl-logo,
.fp-watermark {
    display: none !important;
}


@media (max-width:768px) {
    html {
        font-size: 14px
    }
}

.embedsocial-hashtag{
    width: 100% !important;
}

.es-box {
    border: 0 !important;	
    border: none !important;
    background-color: #181818 !important;
}
